<template>
  <div class="container-with-scroll overflow-scroll">

    <div class="sales-analytics-list-container mt-1">
      <!-- Header -->
      <div class="row border-bottom border-2 m-0 py-1">
        <div class="col-2 pl-0">
          Selection Name
        </div>
        <div class="col-2">
          Store Name
        </div>
        <div class="col-2">
          Shared with
        </div>
        <div class="col-2">
          Shared Date & Time
        </div>
        <div class="col-1 break-label">
          Email Opened
        </div>
        <div class="col-1 break-label">
          Selection Clicked
        </div>
        <div class="col-2 pr-0">
          Opened Date & Time
        </div>
      </div>
      <!-- List -->
      <div
        v-for="selection of selections"
        :key="selection._id"
        class="row border-bottom border-2 m-0 py-1 selection-item"
      >
        <div class="col-2 pl-0">
          <b-link
            class="selection-name"
            @click="onClickSelection(selection)"
          >
            {{ selection.selectionName }}
          </b-link>
        </div>
        <div class="col-2">
          {{ selection.storeName }}
        </div>
        <div class="col-2 font-weight-bold">
          {{ selection.email }}
        </div>
        <div class="col-2">
          {{ getDateAndTime(selection.sharedDate) }}
        </div>
        <div class="col-1">
          {{ selection.isEmailOpened ? 'Yes' : 'No' }}
        </div>
        <div class="col-1">
          {{ selection.isClicked ? 'Yes' : 'No' }}
        </div>
        <div class="col-2 pr-0">
          {{ selection.isEmailOpened ? getDateAndTime(selection.lastEmailOpenedAt) : '' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import moment from 'moment'
import analytics from '@/@core/utils/analytics'
import constants from '@/constants'

export default {
    name: 'SalesAnalyticsList',
    components: {
      BLink,
    },
    props: {
        selections: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
      getDateAndTime(date = '') {
        return date ? moment(date).format('DD/MM/YYYY HH:mmA') : ''
      },
      onClickSelection(selection) {
        this.$router.push({ name: 'selection-products', params: { wishlistId: selection.selectionId } })
        analytics.track(constants.TRACKS.ACTIONS.BRAND_CLICKS_A_SELECTION_IN_SALES_INSIGHTS_PAGE, { selection })
      },
    },
}
</script>

<style lang="scss">
@import 'src/@core/scss/base/bootstrap-extended/_variables.scss';
.container-with-scroll {
  &::-webkit-scrollbar {
    height: 4px;
    width: 0;
  }
  &::-webkit-scrollbar-track {
    border-radius: 0.358rem;
    border-bottom-left-radius: 0;
  }
  &::-webkit-scrollbar-thumb {
    background: $silver-sand;
    border-radius: 0.358rem;
  }
}

    .sales-analytics-list-container {
        word-break: break-all;
        color: $text-color-v3;
        font-size: 14px;
        font-weight: 400;
        min-width: 1000px;

        .break-label {
          word-break: break-word;
        }

        .selection-item {
            .selection-name {
              &:hover {
                text-decoration: underline;
              }
            }
        }
    }
</style>
