<template>
  <div class="sales-analaytics-filter row">
    <div class="col-xs-12 col-md-6 col-xl-6 col-xxl-2 m-0">
      <b-form-group class="position-relative m-0">
        <flat-pickr
          v-model="filterDate"
          class="form-control bg-white date-rage-filter"
          placeholder="Select Date"
          :config="MONTH_CONFIG"
        />
      </b-form-group>
      <feather-icon
        icon="ChevronDownIcon"
        size="16"
        class="flat-pickr-caret-icon"
        @click="$refs.analyticsDate.$el._flatpickr.toggle()"
      />
    </div>
  </div>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue'
import FlatPickr from 'vue-flatpickr-component'
import MonthSelectPlugin from 'flatpickr/dist/plugins/monthSelect' // eslint-disable-line
import analytics from '@/@core/utils/analytics'
import constants from '@/constants'
import { constants as c } from '@kingpin-global/kingpin-utils-frontend'

const { KP_DATE_FORMAT } = c

 const MONTH_CONFIG = {
  dateFormat: KP_DATE_FORMAT.DEFAULT,
  plugins: [
    new MonthSelectPlugin({
      dateFormat: 'M Y',
      shorthand: true,
    }),
  ],
}
export default {
    name: 'SalesAnalyticsFilter',
    components: {
        BFormGroup,
        FlatPickr,
    },
    data() {
        return {
            filterDate: new Date(),
            MONTH_CONFIG,
        }
    },
    watch: {
        filterDate: {
            handler(selectedDate) {
                this.onFilterByDate(selectedDate)
            },
            deep: true,
        },
    },
    methods: {
        onFilterByDate(selectedDate) {
            analytics.track(constants.TRACKS.ACTIONS.BRAND_APPLIES_DATE_FILTER_IN_SALES_INSIGHTS_PAGE, { selectedDate })
            this.$emit('filter-by-date', selectedDate)
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.sales-analaytics-filter {
    .flat-pickr-caret-icon {
        top: 12px !important;
    }
}
</style>
