<template>
  <div
    class="sales-analytics-conainer font-inter"
  >
    <!-- Filter section -->
    <sales-analytics-filter @filter-by-date="onFilterByDate" />

    <div
      v-if="isLoading"
      class="text-center p-5"
    >
      <b-spinner />
    </div>
    <div v-else-if="totalSelections">
      <!-- Sales Analytics list -->
      <sales-analytics-list :selections="selectionList" />
      <!-- pagination -->
      <b-row
        class="mt-2"
      >
        <b-col
          cols="12"
          class="d-flex align-items-center justify-content-center"
        >
          <b-pagination
            v-model="pageNumber"
            :total-rows="totalSelections"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0 page-link-class"
            prev-class="prev-item"
            next-class="next-item"
            @input="onApplyPagination"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
    <div
      v-else
      class="my-2"
    >
      <div class="empty-icon-bg bg-white mb-1 ">
        <feather-icon
          icon="SearchIcon"
          size="22"
        />
      </div>
      <b-card-text>
        <span>
          No selection has been shared.
        </span>
        <b-link
          class="click-here-link"
          @click="routeToShareSelection"
        >
          Click here
        </b-link>
        <span> to share your selection.</span>
      </b-card-text>
    </div>

  </div>

</template>

<script>
import { GET_SALES_ANALYTICS_SELECTIONS_LIST } from '@/store/modules/sales-analytics.module'
import { apiToastError } from '@/@core/utils/toast'
import {
 BCardText, BCol, BLink, BPagination, BRow, BSpinner,
} from 'bootstrap-vue'
import moment from 'moment'
import analytics from '@/@core/utils/analytics'
import constants from '@/constants'
import { RESET_SALES_ANALYTICS_NAV_INDICATOR } from '@/store/modules/notification.module'
import { routePaths } from '@/router/routes/apps'
import SalesAnalyticsFilter from './SalesAnalyticsFilter.vue'
import SalesAnalyticsList from './SalesAnalyticsList.vue'

export default {
    name: 'SalesAnalytics',
    components: {
        SalesAnalyticsList,
        SalesAnalyticsFilter,
        BSpinner,
        BCardText,
        BRow,
        BCol,
        BPagination,
        BLink,
    },
    data() {
        return {
            selectionList: [],
            totalSelections: 0,
            sharedDate: null,
            pageNumber: 1,
            perPage: 10,
            isLoading: false,
        }
    },
    created() {
        analytics.track(constants.TRACKS.ACTIONS.BRAND_OPENS_SALES_INSIGHTS_TAB)
        this.loadSelections()
        this.$store.commit(RESET_SALES_ANALYTICS_NAV_INDICATOR)
    },
    methods: {
      routeToShareSelection() {
        this.$router.push({ name: routePaths.selection })
        analytics.track(constants.TRACKS.ACTIONS.BRNAD_CLICKS_CLICK_HERE_HYPERLINK_IN_SALES_INSIGHTS_PAGE)
      },
      getSharedDateWithFormat() {
        const datePayloadFormat = 'YYYY-MM'
        if (this.sharedDate) {
          const parsedDate = moment(this.sharedDate, 'MMM YYYY', true)
          return parsedDate.format(datePayloadFormat)
        }
        return moment().format(datePayloadFormat)
      },
      loadSelections(isLiveUpdate = false) {
            this.isLoading = !isLiveUpdate
            const params = {
                page: this.pageNumber - 1,
                limit: this.perPage,
                sharedDate: this.getSharedDateWithFormat(),
            }
            this.$store.dispatch(GET_SALES_ANALYTICS_SELECTIONS_LIST, params)
                .then(res => {
                    const resData = res.data.data
                    this.selectionList = resData.salesInsights
                    this.totalSelections = resData.count
                    this.isLoading = false
                })
                .catch(err => {
                    this.isLoading = false
                    apiToastError(err)
                })
        },
        onFilterByDate(date) {
            this.pageNumber = 1
            this.sharedDate = date
            this.loadSelections(true)
        },
        onApplyPagination() {
            analytics.track(constants.TRACKS.ACTIONS.BRAND_APPLIES_PAGINATION_IN_SALES_INSIGHTS_PAGE, { pageNumber: this.pageNumber })
            this.loadSelections(true)
        },
    },
}
</script>

<style lang="scss">
.sales-analytics-conainer {
    .click-here-link {
        text-decoration: underline;
    }
}
</style>
